import React, { FC } from 'react';
import * as styles from './styles.module.css';

export interface RulerProperties {
  isCentered?: boolean;
}

const Ruler: FC<RulerProperties> = ({ isCentered = false }) => (
  <hr className={isCentered ? styles.centeredRuler : styles.ruler} />
);

export default Ruler;
