import React, { FC, ReactChild } from 'react';
import * as styles from './styles.module.css';

export interface ButtonProperties {
  children: ReactChild;
}

const FormButton: FC<ButtonProperties> = ({ children }) => (
  <button className={styles.button} type="submit">
    {children}
  </button>
);

export default FormButton;
