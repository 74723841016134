import React, { FC, ReactChild } from 'react';
import * as styles from './styles.module.css';

export interface SectionParagraphProperties {
  children: ReactChild;
}

const SectionParagraph: FC<SectionParagraphProperties> = ({ children }) => (
  <p className={styles.sectionParagraph}>{children}</p>
);

export default SectionParagraph;
