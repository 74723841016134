import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface FlexProperties {
  children: ReactNode;
}

const Flex: FC<FlexProperties> = ({ children }) => (
  <div className={styles.flex}>{children}</div>
);

export default Flex;
