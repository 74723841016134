import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface HeroProperties {
  children: ReactNode;
}

type Props = HeroProperties;

const Hero: FC<Props> = ({ children }) => (
  <div className={styles.hero}>{children}</div>
);

export default Hero;
