import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface CalloutProperties {
  children: ReactNode;
}

const Callout: FC<CalloutProperties> = ({ children }) => (
  <h3 className={styles.callout}>{children}</h3>
);

export default Callout;
