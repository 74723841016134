import React, { FC } from 'react';
import Logo from '@/components/logo-mark/logo';
import * as styles from './styles.module.css';

const LogoMark: FC = () => (
  <div className={styles.logoMark}>
    <Logo size="64px" />
    <div className={styles.word}>Laravel Certification</div>
  </div>
);

export default LogoMark;
