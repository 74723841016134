import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface SectionSubtitleProperties {
  children: ReactNode;
}

const SectionSubtitle: FC<SectionSubtitleProperties> = ({ children }) => (
  <h4 className={styles.sectionSubtitle}>{children}</h4>
);

export default SectionSubtitle;
