import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface TestimonialsProperties {
  children: ReactNode;
}

const Testimonials: FC<TestimonialsProperties> = ({ children }) => (
  <div className={styles.testimonials}>{children}</div>
);

export default Testimonials;
