import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface ColumnProperties {
  children: ReactNode;
}

const Column: FC<ColumnProperties> = ({ children }) => (
  <div className={styles.column}>{children}</div>
);

export default Column;
