import React from 'react';
import { PageProps } from 'gatsby';
import Hero from '@/components/hero';
import Ruler from '@/components/ruler';
import TopBar from '@/components/top-bar';
import Callout from '@/components/callout';
import Section from '@/components/section';
import DefaultLayout from '@/layouts/default';
import LogoMark from '@/components/logo-mark';
import FormButton from '@/components/form/button';
import InputText from '@/components/form/text-input';
import SectionImage from '@/components/section/image';
import SectionTitle from '@/components/section/title';
import SectionSubtitle from '@/components/section/subtitle';
import SectionParagraph from '@/components/section/paragraph';
import Flex from '@/components/flex';
import Column from '@/components/flex/column';
import Checkout from '@/components/checkout';
import Testimonials from '@/components/testimonials';
import Testimonial from '@/components/testimonials/testimonial';

const Home: React.FC<PageProps> = () => (
  <DefaultLayout>
    <TopBar>
      <LogoMark />
    </TopBar>
    <Hero>
      <h1>
        <em>Show your passion for quality. </em>
        <strong>
          <span className="no-wrap">Stand out</span> from the crowd.
        </strong>
      </h1>
      <h2 className="h3 text-dimmed">Are you ready to boost your career?</h2>
      <form action="#">
        <div className="flex">
          <p>
            <InputText placeholder="Your e-mail address" />
          </p>
          <p>
            <FormButton>Take Practice Test</FormButton>
          </p>
        </div>
        <p className="max-width-600 text-dimmed text-small">
          This practice test covers a range of topics and provides a realistic
          example of what you should expect when taking the exam.
        </p>
      </form>
    </Hero>

    <Section>
      <SectionImage src="./img/sun.png" alt="Rapidly expanding" />
      <SectionTitle>Supercharge your career</SectionTitle>
      <SectionSubtitle>
        Unleash <strong>your full potential</strong>
      </SectionSubtitle>
      <Ruler isCentered />
      <ul className="text-center list-reset">
        <li>
          Earn the <strong>promotion you deserve</strong>
        </li>
        <li>
          <strong>Boost your resume</strong> performance
        </li>
        <li>
          Bring in <strong>more projects and work</strong>
        </li>
      </ul>
    </Section>

    <Callout>
      <strong>Join over 2000 Certified developers</strong> that are
      really,&nbsp;
      <em>
        really, <strong>really serious</strong>
      </em>
      &nbsp;about their craft
    </Callout>

    <Section>
      <SectionImage src="./img/mountain.png" alt="Rapidly expanding" />
      <SectionTitle>Rapidly expanding</SectionTitle>
      <SectionSubtitle>
        <strong>Growing faster</strong> then ever
      </SectionSubtitle>
      <Ruler isCentered />
      <SectionParagraph>
        Laravel is growing faster then bamboo, you The best way to give your
        testimonial a boost is by adding a compelling summary sub-headline.
      </SectionParagraph>
    </Section>

    <Section>
      <Flex>
        <Column>
          <p>
            <img
              src="./img/laptop.svg"
              alt="Land your dream job"
              height="32px"
            />
          </p>
          <h5 className="reset">
            <strong>Land your dream job</strong>
          </h5>
          <Ruler />
          <p className="text-small">
            This is the best time ever to be a developer. But how can you stand
            out as a developer though? There are more and more people coming
            into the game, so you just aren’t competing like it was 2001.
          </p>
        </Column>
        <Column>
          <p>
            <img
              src="./img/globe.svg"
              alt="Worldwide recognition"
              height="32px"
            />
          </p>
          <h5 className="reset">
            <strong>Worldwide recognition</strong>
          </h5>
          <Ruler />
          <p className="text-small">
            No sector encourages and supports remote workers more than tech
            companies, with fierce competition for talent, and a global talent
            pool to call upon. Take advantage and expand your global network.
          </p>
        </Column>
      </Flex>
      <Flex>
        <Column>
          <p>
            <img
              src="./img/fire.svg"
              alt="Show pride in your work"
              height="32px"
            />
          </p>
          <h5 className="reset">
            <strong>Show pride in your work</strong>
          </h5>
          <Ruler />
          <p className="text-small">
            Your skills do not come overnight – its a result of a huge
            investment. Become proud of what youve achieved, it will make you
            more satisfied, more successful, and more likely to recommend.
          </p>
        </Column>
        <Column>
          <p>
            <img
              src="./img/heart.svg"
              alt="Share that you care"
              height="32px"
            />
          </p>
          <h5 className="reset">
            <strong>Share that you care</strong>
          </h5>
          <Ruler />
          <p className="text-small">
            No sector encourages and supports remote workers more than tech
            companies, with fierce competition for talent, and a global talent
            pool to call upon. Take advantage and expand your global network.
          </p>
        </Column>
      </Flex>
    </Section>
    <Section>
      <SectionTitle>This is for you</SectionTitle>
      <SectionSubtitle>
        <strong>Start winning</strong> in a software-driven world
      </SectionSubtitle>
      <Ruler isCentered />
      <SectionParagraph>
        Laravel is growing faster then bamboo, you The best way to give your
        testimonial a boost is by adding a compelling summary sub-headline above
        it. This could be as simple as pulling a powerful quote from a
      </SectionParagraph>
    </Section>
    <Testimonials>
      <Testimonial by="Jeroen Gerits" avatar="https://i.pravatar.cc/150?img=12">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur
        culpa eos facilis pariatur recusandae!
      </Testimonial>
      <Testimonial by="Jeroen Gerits" avatar="https://i.pravatar.cc/150?img=13">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur
        culpa eos facilis pariatur recusandae!
      </Testimonial>
      <Testimonial by="Jeroen Gerits" avatar="https://i.pravatar.cc/150?img=14">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur
        culpa eos facilis pariatur recusandae!
      </Testimonial>
      <Testimonial by="Jeroen Gerits" avatar="https://i.pravatar.cc/150?img=15">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Consectetur
        culpa eos facilis pariatur recusandae!
      </Testimonial>
    </Testimonials>
    <Section>
      <SectionTitle>You are ready for this</SectionTitle>
      <SectionSubtitle>
        Show the world <strong>you mean business</strong>
      </SectionSubtitle>
      <Checkout />
    </Section>
    <Section>
      <SectionTitle>Don’t be anxious</SectionTitle>
      <SectionSubtitle>
        Just give it a shot, <strong>try our free practice test</strong>
      </SectionSubtitle>
      <Ruler isCentered />
      <form action="#">
        <div className="flex box-center">
          <p>
            <InputText placeholder="Your e-mail address" />
          </p>
          <p>
            <FormButton>Take Practice Test</FormButton>
          </p>
        </div>
        <p className="text-small text-dimmed text-center max-width-600">
          This practice test covers the a range of topics and provides a
          realistic example of what you should expect when taking the exam
        </p>
      </form>
    </Section>
    <Section>
      <SectionTitle>What the FAQ?</SectionTitle>
      <div className="max-width-800 box-center">
        <SectionSubtitle>
          We know that you have some questions in mind, we have tried to list
          the most important ones.
        </SectionSubtitle>
      </div>
    </Section>
  </DefaultLayout>
);

export default Home;
