import React, { FC } from 'react';
import * as styles from './styles.module.css';

export interface SectionImageProperties {
  src: string;
  alt: string;
}

const SectionImage: FC<SectionImageProperties> = ({ src, alt }) => (
  <div className="text-center">
    <img className={styles.sectionImage} src={src} alt={alt} />
  </div>
);

export default SectionImage;
