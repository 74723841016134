import React, { FC, ReactChild } from 'react';
import FormButton from '@/components/form/button';
import Ruler from '@/components/ruler';
import { Link } from 'gatsby';
import * as styles from './styles.module.css';

const Checkout: FC = ({ children }) => (
  <div>
    {/* <div> */}
    {/*  <ul className={styles.tabs}> */}
    {/*    <li className={styles.activeTab}>For Professionals</li> */}
    {/*    <li className={styles.tab}> */}
    {/*      <a href="#">For Companies</a> */}
    {/*    </li> */}
    {/*  </ul> */}
    {/* </div> */}
    <div className={styles.checkout}>
      <div>
        <h3>
          <strong>For Professionals</strong>
        </h3>
        <Ruler isCentered />
        <ul className="list-reset">
          <li>45 Multiple choice questions</li>
          <li>Take the exam online at any time</li>
          <li>Guided by a expert proctor</li>
        </ul>
        <br />
        <FormButton>$ 299 Exam Voucher</FormButton>
        <p className="text-small text-dimmed text-center text-italic">
          VAT may be charged depending on your location
        </p>
        <br />
      </div>
      <div>
        <h3>
          <strong>For Organisations</strong>
        </h3>
        <Ruler isCentered />
        <ul className="list-reset">
          <li>Become an official certified organisation</li>
          <li>Visible on our organisation directory</li>
          <li>Discounted exam vouchers for $ 99</li>
        </ul>
        <br />
        <FormButton>$ 1499 Yearly Subscription</FormButton>
        <p className="text-small text-dimmed text-center text-italic">
          VAT may be charged depending on your location
        </p>
        <br />
      </div>
    </div>
    <p className="text-dimmed text-small text-center max-width-900">
      Developers and organizations located in qualifying countries can&nbsp;
      <Link to="/discount/">apply for economy discount.</Link>
    </p>
  </div>
);

export default Checkout;
