import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface SectionProperties {
  children: ReactNode;
}

const Section: FC<SectionProperties> = ({ children }) => (
  <div className={styles.section}>{children}</div>
);

export default Section;
