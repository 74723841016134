import React, { FC } from 'react';
import * as styles from './styles.module.css';

export interface FormTextInputProps {
  placeholder?: string;
}

const FormTextInput: FC<FormTextInputProps> = ({ placeholder }) => (
  <input className={styles.input} type="text" placeholder={placeholder} />
);

export default FormTextInput;
