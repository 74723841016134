import React, { FC, ReactNode } from 'react';
import * as styles from './styles.module.css';

export interface TestimonialProperties {
  by: string;
  avatar: string;
  children: ReactNode;
}

type Props = TestimonialProperties;

const Testimonial: FC<Props> = ({ by, avatar, children }) => (
  <blockquote className={styles.testimonial}>
    <div className={styles.inner}>
      <div>
        <img className={styles.avatar} src={avatar} alt={by} />
      </div>
      <div>
        <p className={styles.quote}>{children}</p>
        <p className={styles.author}>{by}</p>
      </div>
    </div>
  </blockquote>
);

export default Testimonial;
