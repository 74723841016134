import React, { FC, ReactChild } from 'react';
import * as styles from './styles.module.css';

export interface SectionTitleProperties {
  children: ReactChild;
}

const SectionTitle: FC<SectionTitleProperties> = ({ children }) => (
  <h2 className={styles.sectionTitle}>{children}</h2>
);

export default SectionTitle;
